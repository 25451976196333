body {
  margin: 0;
  outline: 0 !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Raleway', sans-serif !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
.carousel-slider {
  /* height: calc(100vh - 73px); */
  margin-top: 73px !important;
}
.main_margin_top {
  padding-top: 100px;
}
.img_con {
  display: block;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 65%;
  width: 100%;
}
.award_title_cs {
  margin-top: 29px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 70px;
  font-family: auto;
}
.with_bg {
  margin-top: 100px;
  background-color: #e2ddd7;
  padding: 38px;
  margin-bottom: 100px;
}
.cat_txt {
  text-align: center;
  font-size: 18px;
}
.cat_txt_pre {
  margin: auto;
  padding-bottom: 20px;
  white-space: pre-line;
  /* font-family: Poppins, sans-serif !important; */
  font-family: 'Raleway', sans-serif !important;

  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
}
.img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: 5px;
}
.award_name {
  color: black;
  font-size: 14px;
  padding-bottom: 28px;
  margin-top: 7px;
}
.award_title {
  text-align: center;
  margin-top: 70px;
  font-weight: 700;
  margin-bottom: 22px;
  font-family: auto;
}
.award_img img {
  /* width: 84px; */
}
.see_more:hover {
  color: darkgray;
}
.see_more {
  position: absolute;
  bottom: 56px;
  left: 39%;
  color: white;
  font-size: 11px;
  font-weight: 600;
}
.award_img div {
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.legend {
  bottom: -13px !important;
  left: 50% !important;
  padding: 0 !important;
  font-size: 14px !important;
  margin-left: -50% !important;
  width: 100% !important;
  opacity: 1 !important;
  background-color: #82777796 !important;
  border-radius: 0 !important;
}

.legend a {
  color: #fff !important;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
}
.legend_cs {
  color: #fff;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  background-color: #82777796;
  position: relative;
  bottom: 38px;
}
.legend_doors {
  color: #fff;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  background-color: #82777796;
  position: relative;
  bottom: 38px;
}
.a_center {
  text-align: center;
}
.carousel .slide {
  background: #b89e9e !important;
}
.main_img_container {
  /* background-color: silver; */
  /* height: 740px; */
  /* margin-top: 12px; */
}
.product_container_title {
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  letter-spacing: 9px;
  font-weight: 100;
}
.products_container {
  padding-top: 19px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.gallery_item_in {
  padding-bottom: 50px;
  padding-top: 50px;
}
.gallery_item_home {
  padding-bottom: 50px;
}
.title_line {
  height: 1px;
  background-color: #efcfcf;
  width: 35%;
  display: block;
}

.category_title {
  color: black;
  text-transform: uppercase;
  width: 100%;
  padding: 7px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 300;
}
.footer_title {
  text-align: center;
  font-size: 12px;
  padding-bottom: 11px;
  padding-top: 10px;
  letter-spacing: 2px;
  font-weight: 400;
}
.menu svg {
  height: 30px;
  width: 30px;
  fill: black;
}
.menu {
  cursor: pointer;
  position: fixed;
  z-index: 11;
  right: 21px;
  top: 15px;
}
.menu_item {
  font-size: 16px;
  font-weight: 400;
}
.menu_item a {
  color: #917a7a;
}
.new_logo_min {
  text-align: center;
}
.menu_item a:hover {
  color: #534949;
}
.lg_s {
  margin-bottom: 65px;
  margin-top: 65px;
}
.show_room_footer {
  font-weight: 700;
}
.representative {
  margin-top: 12px;
  margin-bottom: 12px;
}
.show_room_cus {
}
.show_room_custom {
  flex-direction: column;
}
.show_room {
  /* border-left: 1px solid beige; */
  display: flex;
  padding: 10px;
  height: 190px;
  justify-content: center;
  align-items: center;
}
.show_room_content {
  margin-bottom: 12px;
}
.show_room_title {
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 12px;
}
.blob_class {
  margin-right: 43px;
}
.footer_bg {
  background-color: #e2ddd7;
  margin-top: 125px;
}
.title_con_item {
  text-align: center;
  /* display: flex; */
  /* justify-content: flex-end; */
}
.title_con_item a {
  color: black;
  font-size: 19px;
  font-weight: 600;
}
.social_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.share_container a {
  color: black;
}
.share_container a:hover {
  color: black;
}
.title_con_item a:hover {
  color: black;
}
.title_con {
  display: flex;
  /* width: 90%; */
  justify-content: space-evenly;
  margin-bottom: 30px;
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.logo_container_footer img {
  width: 100%;
}
.logo_container_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.extra_box {
  margin-top: 60px;
}
.logo_container_footer a {
  width: 81%;
}
.logo_container {
  position: fixed;
  border-bottom: 1px solid #efcfcf;
  background-color: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.logo_container img {
  width: 258px;
}
.loading {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading img {
  width: 352px;
}
.col-9-border {
  border-left: 1px solid beige;
}
.blog_border:before {
  /* content: '';
  display: block;
  position: absolute;
  z-index: 1;
  right: 136px;
  top: -29px;
  height: 86px;
  border-left: 1px solid beige; */
}

.image-gallery-left-nav {
  height: 100%;
  outline: 0 !important;
}
.image-gallery-right-nav {
  height: 100%;
  outline: 0 !important;
}
.image-gallery-icon {
  outline: 0 !important;
}
.share_container svg {
  margin-left: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  fill: black;
}
.share_container {
  text-align: center;
  padding-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.write_us_title {
  font-size: 22px;
  padding-bottom: 63px;
  text-align: center;
}
.submit__con {
  height: calc(100vh - 351px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_submit_spam {
  font-size: 16px;
  text-align: center;
}

.after_submit {
  font-size: 26px;
  text-align: center;
}

.form_container {
  padding-top: 15px;
  padding-bottom: 50px;
}
.about_txt {
  font-size: 16px;
  display: flex;
  height: 100%;
  align-items: center;
  text-align: left;
  flex-direction: column;
  justify-content: center;
}
.pr_pa {
  padding-bottom: 50px;
}

.about_con {
  padding-top: 50px;
  padding-bottom: 50px;
}
.info_name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
}
.products_container_cs_other {
  /* padding-top: 145px; */
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.products_container_cs {
  padding-top: 9px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.info_email {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.info_address {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.info_tel {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.info_container {
  padding-bottom: 46px;
  text-align: center;
}
.cs_btn {
  background-color: #d78f8f !important;
  color: white !important;
  height: 33px !important;
  font-size: 13px !important;
}
.cs_txt_area {
  padding: 9px !important;
  font-size: 13px !important;
}
.cs_input {
  padding: 9px !important;
  font-size: 13px !important;
  /* height: 33px !important; */
}
.process_main_img_con {
  padding-top: 13px;
}
.process_title {
  font-size: 18px;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}
.ant-menu-submenu-title {
  padding: 0 !important;
  font-size: 16px !important;
}
.ant-menu-item {
  padding-left: 5px !important;
}
.cs_fx {
  padding-bottom: 80px;
  padding-top: 80px;
}
.cs_fx_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.l_c {
  position: fixed;
  z-index: 3;
  background-color: #fff;
  display: flex;
  width: 100%;
  border-bottom: 1px solid beige;
}
.cs_fx_txt {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}
.product_name_title {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.product_name {
  font-size: 27px;
  margin-bottom: 20px;
}
.new_header {
}
.doors_margin_top {
  margin-top: 50px;
}
.product_category_inside {
  font-size: 17px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.product_category_rail {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: -20px;
}
.product_category {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.new_logo {
  width: 12%;
}

.links_con {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  /* padding-top: 9px; */
  width: 100%;
}
.slash_text_flex {
  display: flex;
  align-items: center;
}
.special {
  margin: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.slash_text {
  margin-left: 5px;
  margin-right: 5px;
}
.slash {
  background-color: #efcfcf;
  width: 30px;
  height: 1px;
}
.links_con a {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ceb8b8;
}
.product_txt {
  font-size: 15px;
  /* word-break: break-all; */
  white-space: pre-line;
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Raleway', sans-serif;
  color: inherit !important;
}
.product_contact_us {
  padding-top: 20px;
}
.c_btn {
  background: none;
  border: none;
}
.contacts_c {
  line-height: 3;
}
.title_con_item_custom {
  /* margin-left: -255px; */
}
.slogan {
  display: flex;
  margin-left: 156px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: 35px; */
  /* width: 100%; */
  font-weight: 600;
}
.footer_pad {
  padding: 29px;
  border-bottom: 1px solid beige;
}
.products_mr {
  margin-right: -15px;
}
.showroom_br {
  border-bottom: 1px solid beige;
}
.col-3-br {
  padding: 15px;
  border-right: 1px solid beige;

  /* border-right: 1px solid beige;
  padding-bottom: 45px;
  padding-top: 46px;
  text-align: center; */

  /* border-top: 1px solid beige;
  border-bottom: 1px solid beige;
  padding-top: 15px;
  padding-bottom: 15px; */
}
.phone_svg {
  width: 13px;
  margin-right: 6px;
}
.slogan_first {
  margin-left: -273px;
  font-size: medium;
}
.slogan_first_main {
  font-size: 17px;
}
.br {
  /* border-left: 1px solid beige; */
  border-right: 1px solid beige;
}
.br-r {
  border-right: 1px solid beige;
}
.footer_con_svg {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
}
.slogan_txt {
  margin-bottom: 60px;
  text-align: center;
}
.contact_us_btn {
  background-color: #e6f7ff;
  padding: 10px;
}
.slick-track {
  width: 100% !important;
}
.slick-slide {
  padding: 26px;
}
.lazy_cl {
  display: flex;
  justify-content: flex-end;
}
.new_text_title {
  font-size: 19px;
  margin-bottom: 31px;
}
.custom_link {
  margin-left: 3px;
  margin-right: 3px;
}
.special_title {
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  margin-bottom: 33px;
}
.product_cat {
  color: black;
  text-align: center;
  font-size: 17px;
  letter-spacing: 3px;
  font-weight: 500;
}

@media (max-width: 1400px) {
  .slogan {
    margin-left: 0;
  }
  .slogan_first {
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .carousel-slider {
    height: auto;
  }
  /* .legend_cs {
    width: 90.5%;
  }
  .legend_doors {
    width: 93.7%;
  } */
}

@media (max-width: 992px) {
  .responsive {
    flex-direction: column-reverse;
  }
  /* .legend_cs {
    width: 87.5%;
  }
  .legend_doors {
    width: 91.7%;
  } */
}

@media (max-width: 768px) {
  /* .legend_doors {
    width: 94.7%;
  }
  .legend_cs {
    width: 94.5%;
  } */
  .main_margin_top {
    padding-top: 50px;
  }
  .social_item {
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 12px;
  }
  .lazy_cl {
    margin-top: 70px;
  }
  .media_cl {
    text-align: center;
    margin-bottom: 70px;
  }
  .award_img_cs {
    padding-bottom: 40px !important;
  }
  .category_name {
    bottom: -9px !important;
  }
  .award_name_blog {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .products_container_cs_other {
    padding-top: 52px;
  }
  .about_txt {
    padding-top: 50px;
  }
  .cs_fx_txt {
    padding-top: 80px;
  }
  .product_name {
    padding-top: 50px;
  }
  .category_title {
    padding: 0 !important;
    font-size: 10px !important;
  }
  .slash {
    display: none;
  }

  .award_name_blog {
    margin: auto;
  }

  .categories_con {
    text-align: center;
  }

  .blog_con {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .products_container_cs {
    padding-top: 114px;
  }
  .slogan_first {
    margin-left: -32px !important;
  }
  .main_img_container {
    /* padding-top: 105px; */
  }
}

.html_text div,
p,
span {
  font-size: 13px;
  overflow-wrap: break-word;
  margin-top: 10px;
  margin-bottom: 10px;
}

.category_name {
  /* position: absolute;
  bottom: 0; */
}

.categories_con {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.cat_name {
  color: #ceb8b8;
  font-size: 14px;
  padding-bottom: 10px;
  margin-top: 7px;
}

.award_name_blog {
  color: black;
  font-size: 14px;
  padding-bottom: 28px;
  margin-top: 7px;
}

.category_title_blog {
  color: black;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 300;
}

.blogs_text {
  line-height: inherit;
  font-size: 16px;
}

.blog_item_con {
  margin: auto;
}
